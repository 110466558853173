export enum LegacyBoxEnv {
  Development = 'dev',
  Staging = 'staging',
  Production = 'prod',
}

export enum OsDeploymentEnvironment {
  Hulk = 'hulk',
  Thor = 'thor',
  Gamora = 'gamora',
  Loki = 'loki',
  Stage = 'stage',
  Thanos = 'thanos',
  Spider = 'spider',
  Fury = 'fury',
  Flash = 'flash',
  Ultron = 'ultron',
  E2E = 'bgroot',
  Prod = 'prd',
  PreProd = 'preprod',
  Elektra = 'elektra',
  WASP = 'wasp',
}

export interface BundleUrlConfigs {
  NEWS_BUNDLE_URL: string
  MARKETPLACE_BUNDLE_URL: string
  ORCHESTRATION_BUNDLE_URL: string
  DEVHUB_BUNDLE_URL: string
  THEME_BUILDER_BUNDLE_URL: string
  MASTERDATA_BUNDLE_URL: string
  AI_ASSISTANT_BUNDLE_URL: string
  PROCESS_BUILDER_BUNDLE_URL: string
}

export interface ApiUrlConfigs {
  RECENT_WORK_API_URL: string
  NEWS_API_URL: string
}

export type Environment = BundleUrlConfigs &
  ApiUrlConfigs & {
    ENV: OsDeploymentEnvironment
    LEGACY_BOX_ENV: string
    KEYCLOAK_AUTHORITY: string
    KEYCLOAK_CLIENT_ID: string
    GENERIC_TENANT_URL: string
    SEGMENT_ANALYTICS_KEY: string
    INTERCOM_APP_ID: string
    INTERCOM_API_BASE: string
    RECENT_WORK_API_URL: string
    NEWS_API_URL: string
    ZENDESK_KEY: string
  }
