import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreateAccessRequestApi } from 'api/tenant/mutation/useCreateAccessRequestApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import styles from 'components/modal/requestTenantModal/RequestTenantModal.module.scss'
import { MAX_CHARACTERS, useValidationScheme } from 'components/modal/requestTenantModal/utils'
import { Modal } from 'components/surface/modal/Modal'
import { useForm } from 'hooks/form/useForm'
import { useOsRoot } from 'providers/osRoot/OsRootContext'
import { useToast } from 'providers/toast/ToastProvider'
import { TenantPublicShort } from 'types/tenants/tenant'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  title: string
  tenants: TenantPublicShort[]
}

const RequestTenantModal = ({ isOpen, title, tenants, onClose, onCloseComplete, id }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { defaultTenant } = useOsRoot()

  const { mutateAsync: handleAccessRequest, isLoading } = useCreateAccessRequestApi()

  const preselectedTenantId = tenants.length === 1 ? tenants[0].id : ''

  const form = useForm({
    defaultValues: { tenantId: preselectedTenantId, requestReason: '' },
    validationSchema: useValidationScheme(),
  })

  const {
    handleSubmit,
    formState: { isValid },
  } = form

  const onSubmit = handleSubmit(async values => {
    try {
      await handleAccessRequest(values)

      enqueueToast({
        message: t('os.common.toasts.request_access'),
        type: 'success',
      })
    } catch {
      enqueueToast({
        message: t('os.common.errors.error'),
        type: 'error',
      })
    } finally {
      onClose()
    }
  })

  return (
    <FormProvider {...form}>
      <Modal
        formConfig={{ onSubmit }}
        open={isOpen}
        data-testid={id}
        onWppModalClose={onClose}
        onWppModalCloseComplete={onCloseComplete}
      >
        <WppTypography slot="header" type="2xl-heading">
          {title}
        </WppTypography>

        <Flex slot="body" direction="column" gap={24}>
          <FormSelect
            required
            name="tenantId"
            placeholder={t('os.request_access_modal.workspace.os_field.placeholder')}
            labelConfig={{ text: t('os.request_access_modal.workspace.os_field.label') }}
            options={tenants}
            className={styles.select}
            getOptionValue={({ id }) => id}
            renderOptionContent={({ name, logoThumbnail }) => (
              <>
                <Avatar
                  slot="left"
                  name={name}
                  src={logoThumbnail?.url || defaultTenant.logoThumbnail?.url || undefined}
                />
                <span slot="label">{name}</span>
              </>
            )}
            data-testid="access-request-tenant-selector"
          />
          <FormTextareaInput
            required
            labelConfig={{ text: t('os.entities.request_reason') }}
            name="requestReason"
            placeholder={t('os.request_access_modal.workspace.request_reason_field.placeholder')}
            charactersLimit={MAX_CHARACTERS}
            warningThreshold={MAX_CHARACTERS}
            data-testid="access-request-reason-input"
          />
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" size="s" onClick={onClose} data-testid="cancel">
            {t('os.common.cancel')}
          </WppButton>
          <WppButton
            type="submit"
            variant="primary"
            size="s"
            disabled={!isValid}
            loading={isLoading}
            data-testid="apply"
          >
            {t('os.request_access_modal.workspace.send_request')}
          </WppButton>
        </Flex>
      </Modal>
    </FormProvider>
  )
}

export const { showModal: showRequestTenantModal } = createNiceModal(RequestTenantModal, 'request-tenant-modal')
