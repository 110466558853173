import { useHubsApi } from 'api/hubs/queries/useHubsApi'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'

export const useUserCanManageHubs = () => {
  const { currentTenant } = useCurrentTenantData()

  const { data } = useHubsApi({
    params: {
      filters: { canManage: true, organizationUnitIds: [] },
      tenantId: currentTenant.id,
    },
  })

  return { isUserCanManage: !!data?.length }
}
