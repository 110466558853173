import { NavigationItemEventDetail, NavigationState } from '@platform-ui-kit/components-library'
import { WppGrid, WppTopbarItem } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { IntercomTourAnchor } from 'components/intercom/IntercomTourAnchor'
import { openIntercomWidget } from 'components/intercom/utils'
import { NavigationMenu } from 'components/navigationMenu/NavigationMenu'
import { OsAssistant } from 'components/osAssistant/OsAssistant'
import { handleOpenWidget } from 'components/zendesk'
import { WIDGET_PAGE_CODES } from 'components/zendesk/utils/constants'
import { ANALYTICS_EVENTS, LaunchLocations, OsModuleNames } from 'constants/analytics'
import { useHomeUrl } from 'hooks/useHomeUrl'
import { useTenantLogoAndMetaData } from 'hooks/useTenantLogoAndMetaData'
import { Breadcrumbs } from 'layout/header/breadcrumbs/Breadcrumbs'
import { useBreadcrumbs } from 'layout/header/breadcrumbs/utils'
import styles from 'layout/header/Header.module.scss'
import { HeaderNavigationProvider } from 'layout/header/headerNavigationProvider/HeaderNavigationProvider'
import { HeaderTeleport } from 'layout/header/HeaderTeleport'
import { HubSwitcher } from 'layout/header/hubSwitcher/HubSwitcher'
import { NotificationsCenter } from 'layout/header/notificationsCenter/NotificationsCenter'
import { TenantSwitcher } from 'layout/header/tenantSwitcher/TenantSwitcher'
import { UserProfileIconMenu } from 'layout/header/userProfileIconMenu/UserProfileIconMenu'
import { getHeaderAppTag, HelpNavigationValue, isHelpNavigationItem, useHeaderMenu } from 'layout/header/utils'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { HubType } from 'types/hubs/hubs'
import { getAnalyticsEventPayload, trackAnalytics, trackAppOpenAnalytics } from 'utils/analytics'
import { simulateLinkClick } from 'utils/links'

export const Header = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { userDetails } = useOtherTenantsAndUserData()
  const { defaultHub } = useCurrentTenantData()
  const homeUrl = useHomeUrl()
  const { appData } = useOsState()
  const breadcrumbs = useBreadcrumbs()
  const headerNavigation = useHeaderMenu()
  const { logoThumbnail, logoOriginal } = useTenantLogoAndMetaData()

  const isBreadcrumbsShown = Boolean(breadcrumbs.items.length)
  const navigationItems = isBreadcrumbsShown ? headerNavigation.slice(0, 1) : headerNavigation
  const contextNavigationItems = isBreadcrumbsShown ? headerNavigation.slice(1) : []
  const selectedNavItemValue = headerNavigation.find(({ path }) => path && pathname.startsWith(path))?.value || ''

  const handleTrackAnalytics = (productName: string) => {
    trackAppOpenAnalytics({
      productName,
      productType: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_TYPE,
      userDetails,
      launchedFrom: LaunchLocations.NavigationMenu,
    })
  }

  const handleNavigateMenu = (event: CustomEvent<NavigationItemEventDetail>) => {
    const { path, value } = event.detail

    switch (value) {
      case HelpNavigationValue.Intercom: {
        trackAnalytics({
          type: AnalyticsActionType.action,
          payload: getAnalyticsEventPayload(
            ANALYTICS_EVENTS.HOME_PAGE.ACTIONS.HOME_NAV_MENU_ITEM_CLICK,
            HelpNavigationValue.Intercom,
          ),
        })
        openIntercomWidget()
        break
      }

      case HelpNavigationValue.Marketplace: {
        handleTrackAnalytics(ANALYTICS_EVENTS.OS_MODULES.PRODUCT_NAME.MARKETPLACE)
        simulateLinkClick({ href: path! })
        break
      }

      case HelpNavigationValue.Support: {
        trackAnalytics({
          type: AnalyticsActionType.page,
          payload: getAnalyticsEventPayload(
            ANALYTICS_EVENTS.HOME_PAGE.ACTIONS.HOME_NAV_MENU_ITEM_CLICK,
            HelpNavigationValue.Support,
          ),
        })
        handleOpenWidget({
          userDetails,
          appOrPageCode: getHeaderAppTag(appData) || WIDGET_PAGE_CODES.OS_INTERNAL_PAGE,
        })
        break
      }

      default: {
        if (path) {
          handleTrackAnalytics(OsModuleNames[value])
          if (isHelpNavigationItem(value)) {
            simulateLinkClick({ href: path, target: '_blank' })
          } else {
            navigate(path)
          }
        }
        break
      }
    }
  }

  return (
    <HeaderNavigationProvider>
      <header className={styles.root}>
        <Flex className={styles.content} align="center">
          <WppGrid container>
            <HeaderTeleport.Target as={WppGrid} item all={24}>
              {/* TODO: Needs refactoring, OsAssistant container shouldn't disappear once rendered */}
              {/* Default OS header. Will be replaced with the deep most HeaderTeleport.Source rendered inside app */}
              <HeaderTeleport.Source>
                <Flex align="center" gap={12}>
                  <IntercomTourAnchor>
                    <NavigationMenu />
                  </IntercomTourAnchor>

                  <Flex align="center">
                    <Link
                      to={homeUrl}
                      onClick={() =>
                        trackAppOpenAnalytics({
                          productName: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_NAME.HOME_PAGE,
                          productType: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_TYPE,
                          userDetails,
                          launchedFrom: LaunchLocations.NavigationMenu,
                          hub_id: defaultHub?.id,
                          hub_name: defaultHub?.name,
                          is_system_hub: defaultHub?.type === HubType.System,
                        })
                      }
                    >
                      {isBreadcrumbsShown ? (
                        <img className={styles.logoCompact} src={logoThumbnail} alt="logo" data-testid="logo-compact" />
                      ) : (
                        <img className={styles.logo} src={logoOriginal} alt="logo" data-testid="logo" />
                      )}
                    </Link>
                    <TenantSwitcher />
                  </Flex>

                  {isBreadcrumbsShown && <Breadcrumbs data={breadcrumbs} />}

                  <Flex className={styles.navigationControls} gap={20}>
                    <Flex gap={4} align="center">
                      <HubSwitcher />

                      {navigationItems.map(navigationItem => (
                        <WppTopbarItem
                          key={navigationItem.value}
                          firstLevel
                          data-testid={navigationItem.value}
                          navigation={navigationItem}
                          activeItems={[selectedNavItemValue]}
                          onWppActiveTopbarItemChange={handleNavigateMenu}
                        />
                      ))}

                      {contextNavigationItems.length > 0 && (
                        <WppTopbarItem
                          firstLevel
                          menu
                          navigation={
                            {
                              children: contextNavigationItems,
                            } as NavigationState
                          }
                          active={contextNavigationItems.some(({ value }) => selectedNavItemValue === value)}
                          activeItems={[selectedNavItemValue]}
                          onWppActiveTopbarItemChange={handleNavigateMenu}
                        />
                      )}
                    </Flex>

                    <OsAssistant />

                    <NotificationsCenter />

                    <UserProfileIconMenu />
                  </Flex>
                </Flex>
              </HeaderTeleport.Source>
            </HeaderTeleport.Target>
          </WppGrid>
        </Flex>
      </header>
    </HeaderNavigationProvider>
  )
}
