import { TreeType } from '@platform-ui-kit/components-library'
import { WppButton, WppCheckbox, WppInput, WppTypography } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useApproveHierarchyAccessRequestApi } from 'api/accessRequests/mutations/useApproveHierarchyAccessRequestApi'
import { useRolesListApi } from 'api/roles/queries/useRolesListApi'
import { useUpdateUsersRolesApi } from 'api/users/mutations/useUpdateUsersRolesApi'
import { Flex } from 'components/common/flex/Flex'
import {
  FormTree,
  getNavigationData,
  updateTreeById,
  updateTreeBySearch,
  updateTreeCloseNotSelected,
} from 'components/form/formTree/FormTree'
import { NoFoundSearchResult } from 'components/form/formTree/noFoundSearchResult/NoFoundSearchResult'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Delay } from 'constants/delay'
import { useForm } from 'hooks/form/useForm'
import { useDateWithTimeFormat } from 'hooks/useDateWithTimeFormat'
import { useDebounceFn } from 'hooks/useDebounceFn'
import {
  HIERARCHY_ACCESS_ROLE,
  ManageHierarchyAccessFormDTO,
  mapFormValuesPayload,
  useValidationSchema,
} from 'pages/admin/requests/hierarchyAccessRequests/assignHierarchyAccessRequestModal/utils'
import {
  handleReloadHierarchyAccessRequestsTables,
  isProcessedRequest,
} from 'pages/admin/requests/hierarchyAccessRequests/utils'
import styles from 'pages/admin/requests/Requests.module.scss'
import { UsersInfoCard } from 'pages/admin/requests/usersInfoCard/UsersInfoCard'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { HierarchyAccessRequest } from 'types/requests/requests'
import { capitalize } from 'utils/common'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'
import { useMapNavigationTree, sortNavigationTree } from 'utils/roles'

interface Props extends NiceModalWrappedProps {
  request: HierarchyAccessRequest
  getAttachmentUrlByKey: (key?: string) => string
  onSubmitted?: () => void
}

const AssignHierarchyAccessRequestModal = ({
  request,
  getAttachmentUrlByKey,
  onSubmitted,
  isOpen,
  onClose,
  onCloseComplete,
  id,
}: Props) => {
  const { enqueueToast } = useToast()
  const { currentTenant } = useCurrentTenantData()
  const { t } = useTranslation()
  const { formatDate } = useDateWithTimeFormat()

  const { mapNavigationTree } = useMapNavigationTree()
  const defaultTree = mapNavigationTree().map(sortNavigationTree)
  const [treeData, setTreeData] = useState<TreeType[]>(defaultTree)

  const [search, setSearch] = useState('')
  const [isChecked, setIsChecked] = useState(false)

  const isExternalUser = request.userData?.isExternal

  const setSearchDebounced = useDebounceFn((searchString?: string) => {
    const trimedSearchString = searchString?.trim() || ''
    trimedSearchString
      ? setTreeData(updateTreeBySearch(treeData, trimedSearchString))
      : setTreeData(updateTreeCloseNotSelected(treeData))
    setSearch(trimedSearchString)
  }, Delay.Tooltip)

  // TODO Temporary solution to get '4.0 Hierarchy Access' role's id per tenant
  const { data } = useRolesListApi({
    params: {
      search: HIERARCHY_ACCESS_ROLE,
      tenantId: currentTenant.id,
    },
  })
  const hierarchyRoleId = data?.[0].id

  const { mutateAsync: handleApproveHierarchyAccessRequest } = useApproveHierarchyAccessRequestApi()
  const { mutateAsync: handleUpdateUsersRoles } = useUpdateUsersRolesApi()

  const form = useForm<ManageHierarchyAccessFormDTO>({
    defaultValues: {
      navigation: [],
    },
    validationSchema: useValidationSchema(),
  })

  const {
    trigger,
    handleSubmit,
    formState: { isValid, isSubmitting },
    setValue,
    watch,
  } = form

  const [navigation] = watch(['navigation'])

  const onSubmit = handleSubmit(async values => {
    try {
      await Promise.all([
        handleApproveHierarchyAccessRequest(request.id),
        hierarchyRoleId &&
          handleUpdateUsersRoles(mapFormValuesPayload(values, hierarchyRoleId, request.userData!.email)),
      ])

      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.HIERARCHY_REQUEST, { id: request.id }] })
      handleReloadHierarchyAccessRequestsTables()

      enqueueToast({
        type: 'success',
        message: capitalize(t('os.requests.actions_modal.toasts.approve_success')),
      })
    } catch (error) {
      if (isProcessedRequest(error)) {
        enqueueToast({
          message: capitalize(t('os.common.errors.processed_request')),
          type: 'error',
        })
      } else {
        enqueueToast({
          message: capitalize(t('os.common.errors.general')),
          type: 'error',
        })
      }
    } finally {
      onSubmitted?.()
      onClose()
    }
  })

  return (
    <FormProvider {...form}>
      <SideModal
        formConfig={{
          onSubmit,
        }}
        open={isOpen}
        size="m"
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        disableOutsideClick
        data-testid={id}
      >
        <WppTypography type="xl-heading" slot="header">
          {t('os.requests.hierarchy_access_requests.actions_modal.assign_hierarchy.title')}
        </WppTypography>

        <Flex slot="body" direction="column" gap={16}>
          {isExternalUser && (
            <WppTypography type="s-strong">
              {t('os.requests.hierarchy_access_requests.actions_modal.assign_hierarchy.description_external')}
            </WppTypography>
          )}
          <UsersInfoCard
            request={request}
            getAttachmentUrlByKey={getAttachmentUrlByKey}
            date={formatDate(request.createdAt)}
          />

          <Flex direction="column" gap={12}>
            <WppTypography type="l-strong">
              {t('os.requests.hierarchy_access_requests.actions_modal.assign_hierarchy.hierarchy.label')}
            </WppTypography>

            {!!treeData?.length && !!treeData[0].children?.length && (
              <WppInput
                size="s"
                type="search"
                onWppChange={({ detail }) => setSearchDebounced(detail.value)}
                placeholder={t('os.requests.hierarchy_access_requests.actions_modal.assign_hierarchy.hierarchy.search')}
                data-testid="assign-hierarchy-search-input"
              />
            )}

            <FormTree
              key={search}
              name="navigation"
              multiple
              data={treeData}
              search={search}
              onWppChange={({ detail }) => {
                setTreeData(updateTreeById(treeData, detail.currentItem?.id!, detail.currentItem!))
                setValue(
                  'navigation',
                  detail.reason === 'select'
                    ? getNavigationData({ currentItem: detail.currentItem!, navigation })
                    : navigation,
                )
                trigger('navigation')
              }}
            />
            <WppCheckbox
              labelConfig={{
                text: t(
                  'os.requests.hierarchy_access_requests.actions_modal.assign_hierarchy.approve_without_selection',
                ),
              }}
              name="approveWithoutSelection"
              onWppChange={({ detail: { checked } }) => setIsChecked(checked)}
              className={styles.approveCheckbox}
            />
            {treeData[0]?.hidden && <NoFoundSearchResult search={search} />}
          </Flex>
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
            {t('os.common.cancel')}
          </WppButton>
          <WppButton
            variant="primary"
            type="submit"
            loading={isSubmitting}
            disabled={(!navigation.length && !isChecked) || !isValid}
            data-testid="apply"
          >
            {capitalize(t('os.requests.hierarchy_access_requests.actions_modal.assign_hierarchy.assign'))}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showAssignHierarchyAccessRequestModal } = createNiceModal(
  AssignHierarchyAccessRequestModal,
  'assign-hierarchy-access-request-modal',
)
