import {
  WppActionButton,
  WppIconChevron,
  WppInput,
  WppListItem,
  WppMenuContext,
  WppMenuGroup,
  WppSkeleton,
  WppSpinner,
  WppTag,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { RefCallback, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useSetState } from 'react-use'

import { useInfiniteHubsListApi } from 'api/hubs/infiniteQueries/useInfiniteHubsListApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { showRequestHubModal } from 'components/modal/requestHubModal/RequestHubModal'
import { ANALYTICS_EVENTS, LaunchLocations } from 'constants/analytics'
import { Delay } from 'constants/delay'
import { TableDefaults } from 'constants/table'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useInfiniteFetchNextPage } from 'hooks/useInfiniteFetchNextPage'
import { useRequestableHubs } from 'hooks/useRequestableHubs'
import styles from 'layout/header/Header.module.scss'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { HubType } from 'types/hubs/hubs'
import { trackAnalytics, trackAppOpenAnalytics } from 'utils/analytics'
import { routesManager } from 'utils/routesManager'

export const HubSwitcher = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { currentTenant, defaultHub } = useCurrentTenantData()
  const { userDetails } = useOtherTenantsAndUserData()
  const { hubId } = useParams()

  const refHubSearch = useRef<HTMLWppInputElement>(null)

  const [{ searchHub, loadMoreRef }, setState] = useSetState<{
    searchHub: string
    loadMoreRef: HTMLDivElement
  }>({
    searchHub: '',
    loadMoreRef: null!,
  })

  const {
    data: hubs,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isLoading,
  } = useInfiniteHubsListApi({
    initialPageParam: {
      page: 1,
    },
    params: {
      searchName: searchHub,
      tenantId: currentTenant.id,
      sort: 'name',
    },
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const hasAccessToOtherHubs = hubs.some(hub => hub.id !== defaultHub?.id)

  const { requestableHubs } = useRequestableHubs()
  const hasRequestableHubs = !!requestableHubs.length

  const setLoadMoreRef: RefCallback<HTMLDivElement> = useCallback(
    node => {
      setState({ loadMoreRef: node! })
    },
    [setState],
  )

  useInfiniteFetchNextPage({
    loadMoreRef,
    isFetching,
    fetchNextPage,
    hasNextPage,
  })

  const setSearchDebounced = useDebounceFn(
    (key: string, search?: string) => setState({ [key]: search?.trim() || '' }),
    Delay.Search,
  )

  return (
    <WppMenuContext
      dropdownConfig={{
        popperOptions: {
          strategy: 'fixed',
        },
      }}
    >
      <WppActionButton
        data-testid="header-hubs-dropdown"
        className={styles.hubsMenuTriggerButton}
        slot="trigger-element"
        variant="secondary"
        onClick={() => {
          setState({ searchHub: '' })
          refHubSearch.current?.setValue('')
          trackAnalytics({
            type: AnalyticsActionType.action,
            payload: ANALYTICS_EVENTS.LIST_OF_HUBS.ACTIONS.LIST,
          })
        }}
      >
        <WppTypography className={styles.hubsMenuButtonTitle} type="s-midi">
          {t('os.header.hubs_selection.title')}
        </WppTypography>

        <WppIconChevron className={styles.tenantMenuTriggerIcon} slot="icon-end" direction="down" />
      </WppActionButton>

      <div>
        {(hubs.length > 3 || searchHub) && (
          <WppMenuGroup withDivider>
            <WppListItem disabled className={styles.searchInputItem}>
              <WppInput
                ref={refHubSearch}
                slot="label"
                size="s"
                type="search"
                className={styles.searchInput}
                onWppChange={({ detail }) => setSearchDebounced('searchHub', detail.value)}
                placeholder={t('os.header.hubs_selection.search_placeholder')}
              />
            </WppListItem>
          </WppMenuGroup>
        )}

        {defaultHub && (
          <WppMenuGroup withDivider={hasAccessToOtherHubs}>
            <WppListItem
              key={defaultHub.id}
              checked={hubId === defaultHub.id}
              onWppChangeListItem={() => {
                trackAppOpenAnalytics({
                  productName: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_NAME.HOME_PAGE,
                  productType: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_TYPE,
                  launchedFrom: LaunchLocations.NavigationMenu,
                  userDetails,
                  hub_id: defaultHub?.id,
                  hub_name: defaultHub?.name,
                  is_system_hub: defaultHub?.type === HubType.System,
                })
                if (hubId !== defaultHub.id) {
                  navigate(routesManager.hubs.root.url(defaultHub.id))
                }
              }}
            >
              <Avatar
                slot="left"
                name={defaultHub.name}
                src={defaultHub.logoThumbnail?.url || defaultHub.logoOriginal?.url || undefined}
              />

              <WppTypography slot="label" type="s-body">
                {defaultHub.name}
              </WppTypography>

              <WppTag slot="right" label={t('os.common.default')} variant="neutral" />
            </WppListItem>
          </WppMenuGroup>
        )}

        <div>
          <WppMenuGroup withDivider={hasRequestableHubs}>
            {hasAccessToOtherHubs &&
              hubs.map(hub => {
                if (hub.id === defaultHub?.id) {
                  return null
                }

                return (
                  <WppListItem
                    key={hub.id}
                    checked={hubId === hub.id}
                    onWppChangeListItem={() => {
                      trackAppOpenAnalytics({
                        productName: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_NAME.HOME_PAGE,
                        productType: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_TYPE,
                        launchedFrom: LaunchLocations.NavigationMenu,
                        userDetails,
                        hub_id: hub?.id,
                        hub_name: hub?.name,
                        is_system_hub: hub?.type === HubType.System,
                      })
                      if (hubId !== hub.id) {
                        navigate(routesManager.hubs.root.url(hub.id))
                      }
                    }}
                  >
                    <Avatar
                      slot="left"
                      name={hub.name}
                      src={hub.logoThumbnail?.url || hub.logoOriginal?.url || undefined}
                    />

                    <WppTypography slot="label" type="s-body">
                      {hub.name}
                    </WppTypography>

                    {!hub.isActive && <WppTag slot="right" label={t('os.hubs.table.inactive')} variant="neutral" />}
                  </WppListItem>
                )
              })}

            {isLoading && (
              <WppListItem disabled className={styles.searchInputItem}>
                <WppSkeleton width={310} height={24} slot="label" />
              </WppListItem>
            )}
            {hasNextPage && (
              <Flex justify="center" ref={setLoadMoreRef}>
                {isFetching && !isLoading && (
                  <WppListItem disabled className={styles.searchInputItem}>
                    <WppSpinner size="s" slot="label" className={styles.loader} />
                  </WppListItem>
                )}
              </Flex>
            )}
          </WppMenuGroup>
        </div>

        {hasRequestableHubs && (
          <WppListItem
            onWppChangeListItem={() =>
              showRequestHubModal({
                title: t('os.request_access_modal.hub.title'),
                hubs: requestableHubs,
              })
            }
          >
            <span slot="label">
              {defaultHub || hasAccessToOtherHubs
                ? t('os.header.hubs_selection.request_access')
                : t('os.header.hubs_selection.request_access_no_hubs')}
            </span>
          </WppListItem>
        )}
        <div className={styles.menuMinWidthLimiter} />
      </div>
    </WppMenuContext>
  )
}
