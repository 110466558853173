import { WppActionButton, WppIconChevron, WppIconTrash, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useTranslation } from 'react-i18next'

import { useGenerateUserDetailsDownloadUrlsApi } from 'api/attachments/queries/useGenerateUserDetailsDownloadUrlsApi'
import { useUserByEmailApi } from 'api/users/queries/useUserByEmailApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import styles from 'components/notificationItemSideModal/NotificationItemSideModal.module.scss'
import { HierarchyApprovedSideModalContent } from 'components/notificationItemSideModal/notificationSideModalContent/HierarchyApprovedSideModalContent'
import { HierarchyRejectedSideModalContent } from 'components/notificationItemSideModal/notificationSideModalContent/HierarchyRejectedSideModalContent'
import { HierarchyRequestedSideModalContent } from 'components/notificationItemSideModal/notificationSideModalContent/HierarchyRequestedSideModalContent'
import { HubApprovedSideModalContent } from 'components/notificationItemSideModal/notificationSideModalContent/HubApprovedSideModalModalContext'
import { HubRejectedSideModalContent } from 'components/notificationItemSideModal/notificationSideModalContent/HubRejectedideModalModalContext'
import { HubRequestedSideModalContent } from 'components/notificationItemSideModal/notificationSideModalContent/HubRequestedSideModalContent'
import { TenantRequestedSideModalContent } from 'components/notificationItemSideModal/notificationSideModalContent/TenantRequestedSideModalContent'
import { Markdown } from 'components/notificationsSideModal/Markdown'
import { NotificationHeader } from 'components/notificationsSideModal/notificationHeader/NotificationHeader'
import {
  hideNotificationsSideModal,
  showNotificationsSideModal,
  useNotificationSideModal,
} from 'components/notificationsSideModal/NotificationsSideModal'
import { useModifyFeed } from 'components/notificationsSideModal/utils'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { EventName, Feed } from 'types/feeds/feeds'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface SideModalNotificationHeaderProps {
  title?: string
  createdAt: Feed['createdAt']
  avatarSrc?: string | null
  avatarName?: string
}
const SideModalNotificationHeader = ({ title, createdAt, avatarName, avatarSrc }: SideModalNotificationHeaderProps) => (
  <Flex align="center" gap={12}>
    <Avatar size="xs" name={avatarName} src={avatarSrc || undefined} data-testid="notification-item-logo" />
    <NotificationHeader title={title} createdAt={createdAt} />
  </Flex>
)

type Props = NiceModalWrappedProps & {
  notification: Feed
  logoUrl?: MayBeNull<string>
}

const NotificationItemSideModal = ({ isOpen, onClose, onCloseComplete, id, notification, logoUrl }: Props) => {
  const { t } = useTranslation()
  const { context, createdAt, eventName } = notification
  const { user_email, request_reason, reject_reason, tenant_name, body, title, tenant_id, hub_name } = context
  const { visible: notificationsSideModalVisible } = useNotificationSideModal()
  const { deleteFeed } = useModifyFeed()

  const { data: user } = useUserByEmailApi({
    params: { email: user_email },
    enabled: isOpen && !!user_email,
  })

  const {
    data: [avatar],
    isLoading: isAvatarLoading,
  } = useGenerateUserDetailsDownloadUrlsApi({
    params: { keys: [user?.avatarThumbnail?.key!] },
    enabled: isOpen && !!user?.avatarThumbnail,
  })

  const handleClose = () => {
    onClose()
    hideNotificationsSideModal()
  }

  const handleBack = () => {
    if (!notificationsSideModalVisible) {
      showNotificationsSideModal()
    }
    onClose()
  }

  const handleDelete = async () => {
    await deleteFeed(notification.id, tenant_id)
    onClose()
  }

  return (
    <SideModal
      data-testid={id}
      open={isOpen}
      className={styles.modal}
      size="m"
      onWppSideModalClose={handleClose}
      onWppSideModalCloseComplete={onCloseComplete}
    >
      <Flex slot="header" justify="between" align="center">
        <Flex align="center" gap={8}>
          <WppActionButton
            className={styles.backButton}
            onClick={handleBack}
            data-testid="notification-item-back-button"
          >
            <WppIconChevron direction="left" color="var(--wpp-grey-color-1000)" />
          </WppActionButton>
          <WppTypography className={styles.title} type="xl-heading" data-testid="notification-item-header">
            {t('os.notification.title')}
          </WppTypography>

          <Flex className={styles.actionsWrapper}>
            <WppActionButton variant="secondary" onClick={handleDelete} data-testid="notification-item-delete-button">
              <WppIconTrash slot="icon-start" />
            </WppActionButton>
          </Flex>
        </Flex>
      </Flex>

      <Flex slot="body" gap={10}>
        <Flex direction="column" gap={20} className={styles.bodyContent}>
          {eventName === EventName.HIERARCHY_ACCESS_REQUESTED_ADMIN && (
            <>
              <SideModalNotificationHeader
                title={title}
                createdAt={createdAt}
                avatarName={tenant_name}
                avatarSrc={logoUrl}
              />
              <HierarchyRequestedSideModalContent
                isOpen={isOpen}
                context={context}
                isAvatarLoading={isAvatarLoading}
                src={avatar?.signed_url}
              />
            </>
          )}
          {eventName === EventName.HIERARCHY_ACCESS_APPROVED && (
            <>
              <SideModalNotificationHeader
                title={title}
                createdAt={createdAt}
                avatarName={tenant_name}
                avatarSrc={logoUrl}
              />
              <HierarchyApprovedSideModalContent requestReason={request_reason} />
            </>
          )}
          {eventName === EventName.HIERARCHY_ACCESS_REJECTED && (
            <>
              <SideModalNotificationHeader
                title={title}
                createdAt={createdAt}
                avatarName={tenant_name}
                avatarSrc={logoUrl}
              />
              <HierarchyRejectedSideModalContent rejectReason={reject_reason} requestReason={request_reason} />
            </>
          )}
          {eventName === EventName.TENANT_ACCESS_REQUESTED_ADMIN && (
            <>
              <SideModalNotificationHeader
                title={title}
                createdAt={createdAt}
                avatarName={tenant_name}
                avatarSrc={logoUrl}
              />
              <TenantRequestedSideModalContent
                isOpen={isOpen}
                context={context}
                isAvatarLoading={isAvatarLoading}
                src={avatar?.signed_url}
              />
            </>
          )}
          {eventName === EventName.HUB_ACCESS_REQUESTED_ADMIN && (
            <>
              <SideModalNotificationHeader
                title={t('os.notification.access.hub.admin_title', { hubName: hub_name })}
                createdAt={createdAt}
                avatarSrc={logoUrl}
              />
              <HubRequestedSideModalContent
                isOpen={isOpen}
                context={context}
                isAvatarLoading={isAvatarLoading}
                src={avatar?.signed_url}
              />
            </>
          )}
          {eventName === EventName.HUB_ACCESS_APPROVED && (
            <>
              <SideModalNotificationHeader
                title={t('os.notification.access.hub.admin_title', { hubName: hub_name })}
                createdAt={createdAt}
                avatarSrc={logoUrl}
              />
              <HubApprovedSideModalContent context={context} />
            </>
          )}
          {eventName === EventName.HUB_ACCESS_REJECTED && (
            <>
              <SideModalNotificationHeader
                title={t('os.notification.access.hub.admin_title', { hubName: hub_name })}
                createdAt={createdAt}
                avatarSrc={logoUrl}
              />
              <HubRejectedSideModalContent hubName={hub_name!} rejectReason={reject_reason!} />
            </>
          )}

          {eventName === EventName.ADMIN_NOTIFICATION_REQUEST && body && (
            <>
              <SideModalNotificationHeader
                title={title}
                createdAt={createdAt}
                avatarName={tenant_name}
                avatarSrc={logoUrl}
              />
              <WppTypography className={styles.message} type="s-body" data-testid="notification-item-content-custom">
                {body}
              </WppTypography>
            </>
          )}

          {eventName === EventName.APPS_NOTIFICATION && body && (
            <>
              <SideModalNotificationHeader
                title={title}
                createdAt={createdAt}
                avatarName={tenant_name}
                avatarSrc={logoUrl}
              />

              <WppTypography className={styles.message} type="s-body" data-testid="notification-item-content-apps">
                <Markdown markdown={body} />
              </WppTypography>
            </>
          )}
        </Flex>
      </Flex>
    </SideModal>
  )
}

export const {
  showModal: showNotificationItemSideModal,
  hideModal: hideNotificationItemSideModal,
  useModal: useNotificationItemSideModal,
} = createNiceModal(NotificationItemSideModal, 'notification-item-side-modal')
