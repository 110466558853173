import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'

import { useApproveHubAccessRequestApi } from 'api/accessRequests/mutations/useApproveHubAccessRequestApi'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { useDateWithTimeFormat } from 'hooks/useDateWithTimeFormat'
import { handleReloadHubAccessRequestsTables } from 'pages/admin/requests/hubAccessRequests/utils'
import styles from 'pages/admin/requests/Requests.module.scss'
import { UsersInfoCard } from 'pages/admin/requests/usersInfoCard/UsersInfoCard'
import { useToast } from 'providers/toast/ToastProvider'
import { HubAccessRequest } from 'types/requests/requests'
import { capitalize } from 'utils/common'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  request: HubAccessRequest
  getAttachmentUrlByKey: (key?: string) => string
  onSubmitted?: () => void
}

const ApproveHubAccessRequestModal = ({
  request,
  getAttachmentUrlByKey,
  isOpen,
  onSubmitted,
  onClose,
  onCloseComplete,
  id,
}: Props) => {
  const { enqueueToast } = useToast()
  const { t } = useTranslation()
  const { formatDate } = useDateWithTimeFormat()

  const { mutateAsync: handleApproveHubAccessRequest, isLoading } = useApproveHubAccessRequestApi()

  const onSubmit = async () => {
    try {
      await handleApproveHubAccessRequest({ id: request.id, tenantId: request.tenantId })

      handleReloadHubAccessRequestsTables()

      enqueueToast({
        type: 'success',
        message: capitalize(t('os.requests.actions_modal.toasts.approve_success')),
      })
    } catch (error) {
      enqueueToast({
        message: capitalize(t('os.common.errors.general')),
        type: 'error',
      })
    } finally {
      onSubmitted?.()
      onClose()
    }
  }
  const isExternalUser = request?.userData?.isExternal
  return (
    <Modal open={isOpen} onWppModalClose={onClose} onWppModalCloseComplete={onCloseComplete} size="m" data-testid={id}>
      <WppTypography type="xl-heading" slot="header">
        {t('os.requests.hub_access_requests.actions_modal.approve_hub_access.title')}
      </WppTypography>

      <Flex slot="body" direction="column" gap={16}>
        {isExternalUser ? (
          <WppTypography type="s-strong">
            {t('os.requests.hub_access_requests.actions_modal.approve_hub_access.description_external')}
          </WppTypography>
        ) : (
          <WppTypography type="s-body">
            <Trans
              i18nKey="os.requests.hub_access_requests.actions_modal.approve_hub_access.description.single"
              values={{
                hubName: request.hubName,
              }}
              components={[
                <span key="0" className={styles.bold}>
                  hubName
                </span>,
              ]}
            />
          </WppTypography>
        )}
        <UsersInfoCard
          request={request}
          getAttachmentUrlByKey={getAttachmentUrlByKey}
          date={formatDate(request.createdAt)}
        />
      </Flex>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
          {t('os.common.cancel')}
        </WppButton>
        <WppButton variant="primary" onClick={onSubmit} loading={isLoading} data-testid="apply">
          {capitalize(t('os.requests.hub_access_requests.actions_modal.approve_hub_access.approve'))}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showApproveHubAccessRequestModal } = createNiceModal(
  ApproveHubAccessRequestModal,
  'assign-hub-access-request-modal',
)
