import { MayBeNull } from '@wpp-open/core'
import { Dispatch, SetStateAction, useEffect } from 'react'

import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { AppData } from 'types/osState/appDataResolved'
import { AppLaunchData } from 'types/osState/appLaunchData'
import { trackAppOpenAnalytics } from 'utils/analytics'
import { isNoCodeAppInstanceApp, mapAppInstanceToApp } from 'utils/appInstances'

interface Props {
  appData: AppData
  appLaunchData: MayBeNull<AppLaunchData>
  setAppLaunchData: Dispatch<SetStateAction<MayBeNull<AppLaunchData>>>
}

export const useTrackAppOpenAnalytics = ({ appData, appLaunchData, setAppLaunchData }: Props) => {
  const { userDetails } = useOtherTenantsAndUserData()
  const { navigationTreeWithHiddenLevel } = useCurrentTenantData()

  const { activeHierarchyWithHiddenLevel, app, appInstance, project, projectPhase, projectItem } = appData

  const appInstanceApp = appInstance ? mapAppInstanceToApp(appInstance) : null
  const isNoCodeApp = appInstanceApp ? isNoCodeAppInstanceApp(appInstanceApp) : false

  useEffect(() => {
    if (appLaunchData && appInstance?.id === appLaunchData?.appInstanceId) {
      trackAppOpenAnalytics({
        appId: appInstance?.devhubMetadata.id,
        appInstanceId: app?.stableId,
        appInstanceName: app?.name,
        productName: appInstance?.devhubMetadata.name,
        productType: app?.type,
        project,
        projectPhase,
        projectItem,
        userDetails,
        activeHierarchyWithHiddenLevel,
        navigationTreeWithHiddenLevel,
        versionId: !isNoCodeApp ? appInstance?.devhubMetadata.version.id || '' : '',
        versionName: !isNoCodeApp ? appInstance?.devhubMetadata.version.name || '' : '',
        versionType: !isNoCodeApp ? appInstance?.devhubMetadata.version.versionType || '' : '',
        launchedFrom: appLaunchData?.launchedFrom,
        source: appLaunchData?.source || '',
      })
      setAppLaunchData(null)
    }
  }, [
    activeHierarchyWithHiddenLevel,
    app?.stableId,
    app?.type,
    app?.name,
    appInstance?.devhubMetadata.id,
    appInstance?.devhubMetadata.name,
    appInstance?.devhubMetadata.version.id,
    appInstance?.devhubMetadata.version.name,
    appInstance?.devhubMetadata.version.versionType,
    appInstance?.id,
    appLaunchData,
    isNoCodeApp,
    navigationTreeWithHiddenLevel,
    project,
    projectItem,
    projectPhase,
    setAppLaunchData,
    userDetails,
  ])
}
