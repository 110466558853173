import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'

import { useApproveTenantAccessRequestsApi } from 'api/accessRequests/mutations/useApproveTenantAccessRequestsApi'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useDateWithTimeFormat } from 'hooks/useDateWithTimeFormat'
import { useUsernameFormat } from 'hooks/useUsernameFormat'
import styles from 'pages/admin/requests/tenantAccessRequests/approveTenantAccessRequestModal/ApproveTenantAccessRequestModal.module.scss'
import {
  handleReloadTenantAccessRequestsTables,
  isProcessedRequest,
} from 'pages/admin/requests/tenantAccessRequests/utils'
import { UsersInfoCard } from 'pages/admin/requests/usersInfoCard/UsersInfoCard'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { TenantAccessRequest } from 'types/requests/requests'
import { capitalize } from 'utils/common'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  request: TenantAccessRequest
  getAttachmentUrlByKey?: (key?: string) => string
  onSubmitted?: () => void
}

const ApproveTenantAccessRequestModal = ({
  request,
  getAttachmentUrlByKey,
  isOpen,
  onSubmitted,
  onClose,
  onCloseComplete,
  id,
}: Props) => {
  const { enqueueToast } = useToast()
  const { t } = useTranslation()
  const { formatUsername } = useUsernameFormat()
  const { formatDate } = useDateWithTimeFormat()

  const { mutateAsync: handleApproveTenantAccessRequests, isLoading } = useApproveTenantAccessRequestsApi()

  const { firstname, lastname, isExternal } = request.userData || {}
  const username = formatUsername(firstname, lastname)

  const handleSubmit = async () => {
    try {
      await handleApproveTenantAccessRequests([request.id])

      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.ACCESS_REQUEST, { id: request.id }] })
      handleReloadTenantAccessRequestsTables()

      enqueueToast({
        type: 'success',
        message: capitalize(t('os.requests.actions_modal.toasts.approve_success')),
      })
    } catch (error) {
      if (isProcessedRequest(error)) {
        enqueueToast({
          message: capitalize(t('os.common.errors.processed_request')),
          type: 'error',
        })
      } else {
        enqueueToast({
          message: capitalize(t('os.common.errors.general')),
          type: 'error',
        })
      }
    } finally {
      onSubmitted?.()
      onClose()
    }
  }

  const title = capitalize([t('os.requests.actions.approve'), t('os.requests.request_types.os_access')].join(' '))

  return (
    <Modal open={isOpen} onWppModalClose={onClose} onWppModalCloseComplete={onCloseComplete} size="m" data-testid={id}>
      <WppTypography type="xl-heading" slot="header">
        {title}
      </WppTypography>
      <Flex slot="body" direction="column" gap={16}>
        {isExternal ? (
          <WppTypography type="s-strong" className={styles.textBody}>
            {t('os.requests.actions_modal.description_external')}
          </WppTypography>
        ) : (
          <WppTypography type="s-body" className={styles.textBody}>
            <Trans
              i18nKey="os.requests.actions_modal.description"
              values={{
                action: t('os.requests.actions.approve'),
                requestType: t('os.requests.request_types.os_access'),
                username,
              }}
              components={[
                <b key="0">action</b>,
                <b key="1">requestType</b>,
                <b className={styles.bold} key="2">
                  username
                </b>,
              ]}
            />
          </WppTypography>
        )}

        {!!getAttachmentUrlByKey && (
          <UsersInfoCard
            request={request}
            getAttachmentUrlByKey={getAttachmentUrlByKey}
            date={formatDate(request.createdAt)}
          />
        )}
      </Flex>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
          {t('os.common.cancel')}
        </WppButton>
        <WppButton variant="primary" type="submit" loading={isLoading} onClick={handleSubmit} data-testid="apply">
          {capitalize(t('os.requests.actions.approve'))}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showApproveTenantAccessRequestModal } = createNiceModal(
  ApproveTenantAccessRequestModal,
  'approve-tenant-access-request-modal',
)
