import { WppActionButton, WppIconPlus, WppTypography } from '@platform-ui-kit/components-library-react'
import { AppInstanceShort } from '@wpp-open/core'
import { Fragment, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/treeList/appsTreeList/AppsTreeList.module.scss'
import { GroupMenuItems } from 'components/treeList/appsTreeList/components/groupMenuItems/GroupMenuItems'
import { useGroupAppInstances } from 'components/treeList/appsTreeList/utils'
import { LoadingTreeList } from 'components/treeList/loadingTreeList/LoadingTreeList'

interface Props {
  isLoading?: boolean
  appInstances: AppInstanceShort[]
  renderApp: (appInstance: AppInstanceShort, i: number, source: AppInstanceShort[]) => JSX.Element
  emptyState?: ReactNode
  action?: ReactNode
  isNoGroupTitle?: boolean
  withAddAppButtonPerSection?: boolean
  handleShowAddAppSideModal?: (appGroupId?: string, appSubGroupId?: string) => void
  withGroupMenu?: boolean
  assignmentId?: string
}

export const AppsTreeList = ({
  isLoading = false,
  appInstances,
  renderApp,
  emptyState = null,
  action = null,
  isNoGroupTitle = false,
  withAddAppButtonPerSection = false,
  handleShowAddAppSideModal,
  withGroupMenu = false,
  assignmentId,
}: Props) => {
  const { t } = useTranslation()
  const { ungrouped, grouped } = useGroupAppInstances({ appInstances })

  if (isLoading) {
    return <LoadingTreeList />
  }

  const renderAddAppButtonPerSection = (appGroupId?: string, appSubGroupId?: string) => {
    if (!withAddAppButtonPerSection) return null
    return (
      <WppActionButton
        onClick={() => !!handleShowAddAppSideModal && handleShowAddAppSideModal(appGroupId, appSubGroupId)}
        data-testid="apps-add-button-per-section"
      >
        <WppIconPlus slot="icon-start" />
        {t('os.admin.apps.add_app_button')}
      </WppActionButton>
    )
  }

  const renderUngroupedApps = (ungroupedApps: AppInstanceShort[]) => {
    if (!ungroupedApps.length) return null
    return (
      <Flex direction="column" gap={2} data-testid="ungrouped-apps">
        {ungroupedApps.map((appInstance, i) => (
          <Fragment key={appInstance.id}>{renderApp(appInstance, i, ungroupedApps)}</Fragment>
        ))}
        {!!ungroupedApps.length && renderAddAppButtonPerSection()}
      </Flex>
    )
  }

  return (
    <Flex direction="column" gap={8} data-testid="apps-tree-list">
      {renderUngroupedApps(ungrouped)}
      {grouped.map(({ group, appInstances, subgroups }, groupIndex) => (
        <Flex key={group.id} className={styles.group} gap={6} direction="column" data-testid="app-group">
          {!isNoGroupTitle && (
            <Flex className={styles.groupHeader} direction="column">
              <Flex align="center" justify="between">
                <WppTypography type="m-strong" data-testid="app-group-header">
                  {group.name}
                </WppTypography>
                {withGroupMenu && (
                  <GroupMenuItems
                    type="groups"
                    current={group}
                    source={grouped}
                    currentIndex={groupIndex}
                    assignmentId={assignmentId}
                  />
                )}
              </Flex>

              {!!group.description && (
                <WppTypography
                  className={styles.groupDescription}
                  type="xs-body"
                  title={group.description}
                  data-testid="app-group-subheader"
                >
                  {group.description}
                </WppTypography>
              )}
            </Flex>
          )}

          <Flex gap={2} direction="column">
            {appInstances.map((appInstance, i) => (
              <Fragment key={appInstance.id}>{renderApp(appInstance, i, appInstances)}</Fragment>
            ))}
            {!!appInstances.length && renderAddAppButtonPerSection(group.id)}
          </Flex>

          {!!subgroups.length &&
            subgroups.map(({ subgroup, appInstances }, subgroupIndex) => (
              <Flex direction="column" className={styles.groupHeader} key={subgroup.id} data-testid="app-sub-group">
                <Flex direction="column">
                  <Flex align="center" justify="between">
                    <WppTypography type="s-strong" data-testid="app-sub-group-header">
                      {subgroup.name}
                    </WppTypography>
                    {withGroupMenu && (
                      <GroupMenuItems
                        current={subgroup}
                        source={subgroups}
                        currentIndex={subgroupIndex}
                        assignmentId={assignmentId}
                        type="subgroups"
                      />
                    )}
                  </Flex>

                  {!!subgroup.description && (
                    <WppTypography type="xs-body" title={subgroup.description} data-testid="app-sub-group-subheader">
                      {subgroup.description}
                    </WppTypography>
                  )}
                </Flex>
                <Flex gap={2} direction="column">
                  {appInstances.map((appInstance, i) => (
                    <Flex data-testid="app-sub-group-item" key={appInstance.id}>
                      {renderApp(appInstance, i, appInstances)}
                    </Flex>
                  ))}
                  {!!appInstances.length && renderAddAppButtonPerSection(group.id, subgroup.id)}
                </Flex>
              </Flex>
            ))}
        </Flex>
      ))}
      {!appInstances.length && emptyState}
      {action}
    </Flex>
  )
}
