import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts'
import { useMemo } from 'react'
import { AuthProvider as OidcProvider, AuthProviderProps } from 'react-oidc-context'
import { Outlet, useLoaderData, useMatch, useNavigate } from 'react-router-dom'

import { environment } from 'environment'
import { useDefaultLoginRedirectUrl } from 'hooks/useDefaultLoginRedirectUrl'
import { useStableCallback } from 'hooks/useStableCallback'
import { SignInUserState } from 'types/auth/state'
import { routesManager } from 'utils/routesManager'

export const OsOidcProvider = () => {
  const navigate = useNavigate()
  // Route loader on the same level uses getShouldMonitorSession() util to get the value
  const shouldMonitorSession = useLoaderData() as boolean
  const isMiroAuthRedirectCallback = !!useMatch(routesManager.miroAuthCallback.url())
  const isWrikeAuthRedirectCallback = !!useMatch(routesManager.wrikeAuthCallback.url())
  const defaultTargetUrl = useDefaultLoginRedirectUrl()

  const oidcConfiguration: UserManagerSettings = useMemo(
    () => ({
      accessTokenExpiringNotificationTimeInSeconds: 120,
      authority: environment.KEYCLOAK_AUTHORITY,
      automaticSilentRenew: true, // Using refresh token
      client_id: environment.KEYCLOAK_CLIENT_ID,
      loadUserInfo: false, // The default user email is enough for now
      // TODO: monitorSession is causing login issues in FF & Safari
      //  due to strict cookie policies
      monitorSession: shouldMonitorSession, // Detect logouts from a different tab, session expiration, etc.
      post_logout_redirect_uri: `${window.location.origin}${routesManager.login.url()}`,
      redirectMethod: 'assign',
      redirect_uri: `${window.location.origin}${routesManager.authCallback.url()}`,
      scope: 'openid',
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    }),
    [shouldMonitorSession],
  )

  const handleSignInCallback: NonNullable<AuthProviderProps['onSigninCallback']> = useStableCallback(user => {
    if (user) {
      const userState = user.state as SignInUserState | undefined
      const targetUrl = userState?.targetUrl || defaultTargetUrl

      navigate(targetUrl, {
        replace: true,
      })
    }
  })

  return (
    <OidcProvider
      {...oidcConfiguration}
      onSigninCallback={handleSignInCallback}
      // Miro and Wrike OAuth2 flow is a subset of OIDC and uses same query parameters
      // so in this case we skip oidc-client tries to authenticate with miro or wrike "state" and "code" parameter
      skipSigninCallback={isMiroAuthRedirectCallback || isWrikeAuthRedirectCallback}
    >
      <Outlet />
    </OidcProvider>
  )
}
