import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { TenantPublicShort } from 'types/tenants/tenant'

export const fetchAllTenantsApi = ({ page = 1, itemsPerPage = 1000 }: PaginationParams<{}>) =>
  facadeApi.get<PaginatedResponse<TenantPublicShort>>('/tenants/all', {
    params: {
      page,
      itemsPerPage,
    },
  })
