import { getChildHierarchyLevels } from '@wpp-open/core'
import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useMePermissionByTenantApi } from 'api/alphaZulu/queries/useMePermissionByTenantApi'
import { useHubApi } from 'api/hubs/queries/useHubApi'
import { useHubsApi } from 'api/hubs/queries/useHubsApi'
import { useNavigationTreeApi } from 'api/navigation/queries/useNavigationTreeApi'
import { useUserAssignedRolesApi } from 'api/roles/queries/useUserAssignedRolesApi'
import { useTenantApi } from 'api/tenant/queries/useTenantApi'
import { is403Error } from 'api/utils'
import { ForbiddenOSAccessError, OsIsNotAvailableError } from 'components/renderError'
import { useInitSegmentAnalytics } from 'hooks/useInitSegmentAnalytics'
import { useProviderNoncriticalError } from 'hooks/useProviderNoncriticalError'
import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import { CurrentTenantDataContext } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsRoot } from 'providers/osRoot/OsRootContext'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { HubType } from 'types/hubs/hubs'
import { useTreeWithoutHiddenLevel } from 'utils/mapping/common'
import { getPreferredHubId } from 'utils/user'

/**
 * Provides necessary data for a non-generic current tenant
 */
export const CurrentTenantDataProvider = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation()
  const { hostInfo } = useOsRoot()
  const { userDetails } = useOtherTenantsAndUserData()
  const [hasFailedLoadingPreferredHub, setHasFailedLoadingPreferredHub] = useState(false)

  const currentTenantId = hostInfo.currentTenant!.id
  const preferredHubId = getPreferredHubId({ userDetails, tenantId: currentTenantId })

  const {
    data: currentTenantFull,
    isLoading: isCurrentTenantFullLoading,
    error: currentTenantFullError,
  } = useTenantApi({
    params: {
      tenantId: currentTenantId,
    },
  })

  const shouldLoadPreferredHub = !!preferredHubId && !hasFailedLoadingPreferredHub

  const {
    data: preferredHub,
    isLoading: isPreferredHubLoading,
    isError: isPreferredHubError,
  } = useHubApi({
    params: {
      hubId: preferredHubId!,
    },
    enabled: shouldLoadPreferredHub,
  })

  // Prevent endless loading cycle with further tries to load preferred hub if it has already failed.
  // This can happen if the user goes to the hub page, which will also load this hub.
  // It will in turn set isPreferredHubLoading to true again, which will unmount the hub page and so on.
  useEffect(() => {
    if (isPreferredHubError) {
      setHasFailedLoadingPreferredHub(true)
    }
  }, [isPreferredHubError])

  const shouldLoadSystemHubs = !shouldLoadPreferredHub || isPreferredHubError

  const {
    data: systemHubs,
    isLoading: isSystemHubsLoading,
    isError: isSystemHubsError,
  } = useHubsApi({
    params: {
      tenantId: currentTenantId,
      filters: {
        type: HubType.System,
        isActive: true,
      },
      itemsPerPage: 1,
    },
    enabled: shouldLoadSystemHubs,
  })

  const shouldLoadOtherHubs = (shouldLoadSystemHubs && !isSystemHubsLoading && !systemHubs.at(0)) || isSystemHubsError

  const {
    data: otherAvailableHubs,
    isLoading: isOtherAvailableHubsLoading,
    isError: isOtherAvailableHubsError,
  } = useHubsApi({
    params: {
      tenantId: currentTenantId,
      filters: {
        isActive: true,
      },
      itemsPerPage: 1,
    },
    enabled: shouldLoadOtherHubs,
  })

  const defaultHub = preferredHub || systemHubs.at(0) || otherAvailableHubs.at(0) || null

  const navigationHierarchy = useMemo(
    () => (currentTenantFull ? getChildHierarchyLevels(currentTenantFull) : []),
    [currentTenantFull],
  )

  const navigationHierarchyWithoutHiddenLevel = useMemo(
    () =>
      currentTenantFull && currentTenantFull.flags.isFirstLevelHidden && navigationHierarchy.length > 0
        ? navigationHierarchy.slice(1)
        : navigationHierarchy,
    [currentTenantFull, navigationHierarchy],
  )

  const {
    data: userAssignedRoles,
    isError: isUserAssignedRolesError,
    isLoading: isUserAssignedRolesLoading,
  } = useUserAssignedRolesApi({
    params: { email: userDetails.email, id: userDetails.id },
  })

  const {
    isLoading: isPermissionsLoading,
    data: permissions,
    isError: isPermissionsError,
  } = useMePermissionByTenantApi({
    params: {
      account_ids: [currentTenantFull?.azMeta.organizationsId!],
    },
    enabled: !!currentTenantFull,
  })

  const {
    isLoading: isNavigationTreeLoading,
    data: navigationTree,
    error: navigationTreeError,
  } = useNavigationTreeApi({
    params: {
      tenantId: currentTenantId,
    },
  })

  const navigationTreeWithoutHiddenLevel = useTreeWithoutHiddenLevel({
    currentTenant: currentTenantFull,
    tree: navigationTree,
  })

  useProviderNoncriticalError({
    isError: isPreferredHubError || isSystemHubsError || isOtherAvailableHubsError,
    message: t('os.provider_errors.default_hub'),
  })

  useProviderNoncriticalError({
    isError: isUserAssignedRolesError,
    message: t('os.provider_errors.user_assigned_roles'),
  })

  useProviderNoncriticalError({
    isError: isPermissionsError,
    message: t('os.provider_errors.permissions'),
  })

  // without memoizing the value, at each rerender we would send the same object but with a different
  // reference that would cause some unnecessary rerendering inside the custom hook
  const roles = useMemo(() => {
    return !isUserAssignedRolesLoading
      ? userAssignedRoles.map(({ role_name }) => ({
          role_name,
        }))
      : null
  }, [userAssignedRoles, isUserAssignedRolesLoading])

  const isLoading =
    isCurrentTenantFullLoading ||
    isPreferredHubLoading ||
    isSystemHubsLoading ||
    isOtherAvailableHubsLoading ||
    isPermissionsLoading ||
    isNavigationTreeLoading
  const criticalError = currentTenantFullError || navigationTreeError

  useInitSegmentAnalytics({
    currentTenant: currentTenantFull,
    userDetails,
    roles,
  })

  if (isLoading) {
    return <LoadingPage />
  }

  if (criticalError) {
    return is403Error(criticalError) ? <ForbiddenOSAccessError /> : <OsIsNotAvailableError />
  }

  return (
    <CurrentTenantDataContext.Provider
      value={{
        currentTenant: currentTenantFull!,
        defaultHub,
        permissions: permissions!,
        navigationHierarchy: navigationHierarchyWithoutHiddenLevel,
        navigationHierarchyWithHiddenLevel: navigationHierarchy,
        navigationTree: navigationTreeWithoutHiddenLevel!,
        navigationTreeWithHiddenLevel: navigationTree!,
      }}
    >
      {children}
    </CurrentTenantDataContext.Provider>
  )
}
