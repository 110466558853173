import { WppIconApp, WppListItem } from '@platform-ui-kit/components-library-react'

import { BreadcrumbMenuAppItem } from 'layout/header/breadcrumbs/common/menuAppItem/BreadcrumbMenuAppItem'
import { useProjectBreadcrumbsApps } from 'layout/header/breadcrumbs/project/appsProvider/ProjectBreadcrumbsAppsProvider'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { ProjectCanvasApplicationShort } from 'types/projects/projectNavigation'
import { isValidAppInstanceHierarchyPath } from 'utils/appInstances'

interface Props {
  canvasApp: ProjectCanvasApplicationShort
}

export const ProjectBreadcrumbsMenuAppItem = ({ canvasApp }: Props) => {
  const { navigationTreeWithHiddenLevel } = useCurrentTenantData()
  const { appData } = useOsState()
  const { appsMap, appsInstances } = useProjectBreadcrumbsApps()

  const { app, activeHierarchyWithHiddenLevel } = appData
  const projectApp = canvasApp.appInstanceId ? appsMap[canvasApp.appInstanceId] : null
  const appInstance = canvasApp.appInstanceId ? appsInstances[canvasApp.appInstanceId] : null
  const canLaunch =
    !!projectApp &&
    isValidAppInstanceHierarchyPath({
      app: projectApp,
      activeHierarchyWithHiddenLevel,
      mappingWithHiddenLevel: navigationTreeWithHiddenLevel.mapping,
    })

  if (canLaunch) {
    const isChecked = projectApp.stableId === app?.stableId

    return (
      <div>
        <BreadcrumbMenuAppItem
          isChecked={isChecked}
          app={projectApp}
          customAppName={canvasApp.name}
          appInstanceDevHubName={appInstance?.devhubMetadata?.name}
          appInstanceDevHubId={appInstance?.devhubMetadata?.id}
        />
      </div>
    )
  }

  return (
    <div>
      <WppListItem disabled data-testid="breadcrumbs-menu-app-item">
        <WppIconApp slot="left" />
        <span slot="label">{canvasApp.name || projectApp?.name || canvasApp.externalAppName}</span>
      </WppListItem>
    </div>
  )
}
