import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { Markdown } from 'components/notificationsSideModal/Markdown'
import styles from 'components/notificationsSideModal/notificationCard/NotificationCard.module.scss'
import { useGetFormattedTime } from 'pages/home/systemWidgets/calendarWidget/utils'
import { EventName, FeedContext } from 'types/feeds/feeds'

export const HierarchyRequestedCardContent = ({ userName }: { userName: FeedContext['user_name'] }) => (
  <WppTypography type="s-body" data-testid="notification-card-content-hierarchy-requested">
    <Trans
      i18nKey="os.notification.access.hierarchy.requested_access"
      values={{
        userName: `<0>${userName}</0>`,
      }}
      components={[
        <WppTypography key="0" type="s-strong">
          userName
        </WppTypography>,
      ]}
    />
  </WppTypography>
)

export const TenantRequestedCardContent = ({
  userName,
  tenantName,
}: {
  userName: FeedContext['user_name']
  tenantName: FeedContext['tenant_name']
}) => (
  <Truncate type="s-body" lines={2} data-testid="notification-card-content-tenant-requested">
    <Trans
      i18nKey="os.notification.access.tenant.requested_access"
      values={{
        userName: `<0>${userName}</0>`,
        tenantName: `<1>${tenantName}</1>`,
      }}
      components={[
        <WppTypography key="0" type="s-strong">
          userName
        </WppTypography>,
        <WppTypography key="1" type="s-strong">
          tenantName
        </WppTypography>,
      ]}
    />
  </Truncate>
)

export const HierarchyAccessInfoCardContent = ({
  eventName,
  requestReason,
  rejectReason,
}: {
  eventName: EventName.HIERARCHY_ACCESS_REJECTED | EventName.HIERARCHY_ACCESS_APPROVED
  requestReason: FeedContext['request_reason']
  rejectReason: FeedContext['reject_reason']
}) => {
  const { t } = useTranslation()
  const isRequestApproved = eventName === EventName.HIERARCHY_ACCESS_APPROVED

  return (
    <Flex direction="column">
      <WppTypography type="s-body" data-testid="notification-card-content-hierarchy-access">
        <Trans
          i18nKey={`os.notification.access.${isRequestApproved ? 'approved_access' : 'rejected_access'}`}
          values={{
            type: `<0>${t('os.notification.access.hierarchy.navigation')}</0>`,
          }}
          components={[
            <WppTypography key="0" type="s-strong">
              type
            </WppTypography>,
          ]}
        />
      </WppTypography>
      {(requestReason || rejectReason) && (
        <Truncate lines={1} type="xs-body" className={styles.greyTypography} data-testid="notification-card-reason">
          {isRequestApproved ? requestReason : rejectReason}
        </Truncate>
      )}
    </Flex>
  )
}

export const CustomNotificationCardContent = ({ text }: { text: FeedContext['body'] }) => (
  <Truncate lines={2} type="s-body" className={styles.message} data-testid="notification-card-content-custom">
    {text}
  </Truncate>
)

export const AppsNotificationCardContent = ({ body }: { body: FeedContext['body'] }) => {
  return (
    body && (
      <Truncate className={styles.appsMarkdown} lines={2} type="s-body" data-testid="notification-card-content-apps">
        <Markdown markdown={body} />
      </Truncate>
    )
  )
}

export const NewNotificationCardContent = ({ text }: { text: FeedContext['title'] }) => {
  return (
    <Truncate className={styles.appsMarkdown} lines={2} type="s-strong" data-testid="notification-card-content-apps">
      {text}
    </Truncate>
  )
}

export const HubRequestedCardContent = ({
  userName,
  hubName,
}: {
  userName: FeedContext['user_name']
  hubName?: FeedContext['hub_name']
}) => (
  <Truncate type="s-body" lines={2} data-testid="notification-card-content-tenant-requested">
    <Trans
      i18nKey="os.notification.access.hub.requested_access"
      values={{
        userName: `<0>${userName}</0>`,
        hubName,
      }}
      components={[
        <WppTypography key="0" type="s-strong">
          userName
        </WppTypography>,
        <WppTypography key="1" type="s-strong">
          tenantName
        </WppTypography>,
      ]}
    />
  </Truncate>
)

export const HubRequestedCompiledCardContent = ({
  hubName,
  eventName,
  rejectReason,
}: {
  hubName?: FeedContext['hub_name']
  requestReason: FeedContext['request_reason']
  rejectReason: FeedContext['reject_reason']
  eventName: EventName.HUB_ACCESS_APPROVED | EventName.HUB_ACCESS_REJECTED
}) => {
  const { t } = useTranslation()
  const isRequestApproved = eventName === EventName.HUB_ACCESS_APPROVED

  return (
    <Flex direction="column">
      <Truncate type="s-body" lines={2} data-testid="notification-card-content-tenant-requested">
        <Trans
          i18nKey={`os.notification.access.${isRequestApproved ? 'approved_access' : 'rejected_access'}`}
          values={{
            type: `<0>${t('os.notification.access.hub.admin_title', { hubName })}</0>`,
          }}
          components={[
            <WppTypography key="0" type="s-strong">
              type
            </WppTypography>,
          ]}
        />
      </Truncate>
      {rejectReason && (
        <Truncate lines={1} type="xs-body" className={styles.greyTypography} data-testid="notification-card-reason">
          {t('os.notification.access.hub.rejected_access', { hubName })} {rejectReason}
        </Truncate>
      )}
    </Flex>
  )
}

export const UpcomingEventCardContent = ({
  title,
  startsAt,
  endsAt,
}: {
  title: FeedContext['title']
  startsAt: FeedContext['starts_at']
  endsAt: FeedContext['ends_at']
}) => {
  const getFormattedTime = useGetFormattedTime()

  return (
    <Flex direction="column">
      <Truncate lines={1} type="s-strong" data-testid="notification-card-title">
        {title}
      </Truncate>
      {startsAt && endsAt && (
        <Truncate lines={1} type="s-body" data-testid="notification-card-time">
          {getFormattedTime(new Date(startsAt), new Date(endsAt))}
        </Truncate>
      )}
    </Flex>
  )
}
