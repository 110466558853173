import { WppMenuContext, WppListItem, WppMenuGroup, WppIconWarning } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import clsx from 'clsx'
import { ParseKeys } from 'i18next'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation, Location, useNavigate } from 'react-router-dom'

import { Avatar } from 'components/common/avatar/Avatar'
import { ANALYTICS_EVENTS, LaunchLocations } from 'constants/analytics'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermission'
import { useUserCanManageHubs } from 'hooks/useUserCanManageHubs'
import styles from 'layout/header/userProfileIconMenu/UserProfileIconMenu.module.scss'
import { useAuth } from 'providers/auth/AuthContext'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { trackAnalytics, trackAppOpenAnalytics } from 'utils/analytics'
import { join } from 'utils/common'
import { routesManager } from 'utils/routesManager'

interface MenuItem {
  link: string
  label: ParseKeys
  show?: boolean
  disabled?: boolean
  permission?: string
  isActive?: (location: Location) => boolean
}

const adminSection: MenuItem[] = [
  {
    link: routesManager.admin.settings.url(),
    label: 'os.header.user_avatar_dropdown_menu.os_settings',
    permission: Permission.OS_ADMIN_SETTING_ACCESS,
    isActive: ({ pathname }) => pathname.startsWith(routesManager.admin.root.url()),
  },
]

const youSection: MenuItem[] = [
  {
    link: routesManager.user.profile.url(),
    label: 'os.header.user_avatar_dropdown_menu.profile',
    permission: Permission.OS_USERDETAILS_APP_ACCESS,
    isActive: ({ pathname }) => pathname.startsWith(routesManager.user.root.url()),
  },
]

export const UserProfileIconMenu = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { isUserCanManage } = useUserCanManageHubs()
  const { userDetails } = useOtherTenantsAndUserData()
  const { hasPermission } = useHasPermission()
  const { logout } = useAuth()
  const { t } = useTranslation()

  const { firstname, lastname, avatarUrl, countryAlpha2Code } = userDetails

  const renderItems = (menuItems: MenuItem[]) =>
    menuItems
      .filter(({ permission }) => hasPermission(permission))
      .map(({ link, label, disabled = false, isActive = ({ pathname }) => !!matchPath(link, pathname) }) => {
        const translation = t(label) as string

        return (
          <WppListItem
            key={translation}
            data-testid={translation}
            disabled={disabled}
            className={clsx({
              [styles.active]: isActive(location),
            })}
            linkConfig={{
              href: link,
              onClick: e => {
                e.preventDefault()
                trackAnalytics({
                  type: AnalyticsActionType.page,
                  payload:
                    link === routesManager.admin.settings.url()
                      ? ANALYTICS_EVENTS.ADMIN.PAGE.OS_SETTINGS
                      : ANALYTICS_EVENTS.USER.PAGE.PROFILE,
                })
                trackAppOpenAnalytics({
                  productName:
                    link === routesManager.admin.settings.url()
                      ? ANALYTICS_EVENTS.OS_MODULES.PRODUCT_NAME.ADMIN_SETTINGS
                      : ANALYTICS_EVENTS.OS_MODULES.PRODUCT_NAME.PROFILE,
                  productType: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_TYPE,
                  userDetails,
                  launchedFrom: LaunchLocations.NavigationMenu,
                })
                navigate(link)
              },
            }}
          >
            <span slot="label" className={styles.listItemLabel}>
              {translation}
            </span>
            {!countryAlpha2Code && link === routesManager.user.profile.url() && (
              <WppIconWarning
                width={18}
                slot="right"
                data-testid="user-menu-profile-warning"
                className={styles.warningIconColor}
              />
            )}
          </WppListItem>
        )
      })

  const menuUserName = join([firstname, lastname?.[0]], ' ')
  const adminItems = renderItems(adminSection)

  return (
    <WppMenuContext listWidth="200px" data-testid="user-menu">
      <div slot="trigger-element" className={styles.avatarContainer}>
        <Avatar name={firstname} src={avatarUrl || undefined} size="s" className={styles.avatar} />
        {!countryAlpha2Code && (
          <WppIconWarning width={18} className={styles.warningIcon} data-testid="user-avatar-warning" />
        )}
      </div>
      <div>
        {(isUserCanManage || !!adminItems.length) && (
          <WppMenuGroup
            className={styles.menuGroup}
            header={t('os.header.user_avatar_dropdown_menu.admin_section_header')}
          >
            {adminItems.length ? (
              adminItems
            ) : (
              <WppListItem
                key={t('os.header.user_avatar_dropdown_menu.hubs_settings')}
                data-testid={t('os.header.user_avatar_dropdown_menu.hubs_settings')}
                className={clsx({
                  [styles.active]: location.pathname.includes(routesManager.hubsAdmin.root.routePath),
                })}
                linkConfig={{
                  href: routesManager.hubsAdmin.root.url(),
                  onClick: e => {
                    e.preventDefault()
                    trackAnalytics({
                      type: AnalyticsActionType.page,
                      payload: ANALYTICS_EVENTS.ADMIN.PAGE.HUBS_ADMIN,
                    })
                    trackAppOpenAnalytics({
                      productName: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_NAME.HUBS_ADMIN,
                      productType: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_TYPE,
                      userDetails,
                      launchedFrom: LaunchLocations.NavigationMenu,
                    })
                    navigate(routesManager.hubsAdmin.root.url())
                  },
                }}
              >
                <span slot="label" className={styles.listItemLabel}>
                  {t('os.header.user_avatar_dropdown_menu.hubs_settings')}
                </span>
              </WppListItem>
            )}
          </WppMenuGroup>
        )}
      </div>
      <WppMenuGroup className={styles.menuGroup} withDivider header={menuUserName}>
        {renderItems(youSection)}
      </WppMenuGroup>

      <WppListItem
        onWppChangeListItem={() => {
          logout()
        }}
      >
        <span slot="label">{t('os.header.user_avatar_dropdown_menu.log_out')}</span>
      </WppListItem>
    </WppMenuContext>
  )
}
